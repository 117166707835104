// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c34008d9-b49b-4e89-985b-08f54da64b90",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_BjxGUL7kr",
    "aws_user_pools_web_client_id": "29j0opvh0ce5de3t5265t08rkp",
    "aws_appsync_graphqlEndpoint": "https://hrolbskffrdwni3iozjd67mswq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "project-box87cdda7931d04a9bb33d0e8f1421c511-master",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
